import * as Mui from "@mui/material";

type Props = {
  title?: string;
  subtitle?: string;
};

const SignUpHeader = (props: Props) => {
  return (
    <Mui.Stack spacing={2}>
      {props.title && (
        <Mui.Typography variant="h3">{props.title}</Mui.Typography>
      )}
      {props.subtitle && (
        <Mui.Typography variant="body2" color={"paragraph.passive"}>
          {props.subtitle}
        </Mui.Typography>
      )}
    </Mui.Stack>
  );
};

export default SignUpHeader;
