import anquan_boldin_img from "assets/photos/anquan_boldin.png";
import isaiah_adams_img from "assets/photos/isaiah_adams.png";
import james_graham_img from "assets/photos/james_graham.png";
import sy_chatman_img from "assets/photos/sy_chatman.png";

export interface IPublicUser {
  avatar: string;
  name: string;
  username: string;
  jerseyNumber: string;
  brandScore: number;
  athleticScore: number;
  socialScore: number;
  academicScore: number;
  achievementScore: number;
  teamRank: string;
  leagueRank: number;
  class: string;
  birthday: string;
  height: string;
  weight: number;
  university: string;
  sport: string;
  hometown: string;
  brandHistory: {
    month: string;
    value: number;
  }[];
}

export const publicUsers: IPublicUser[] = [
  {
    avatar: anquan_boldin_img,
    username: "anquanboldin_",
    name: "Anquan Boldin Jr.",
    jerseyNumber: "#4",
    brandScore: 419.00,
    athleticScore: 105.00,
    socialScore: 161.00,
    academicScore: 133.00,
    achievementScore: 20.00,
    // academicScore: 50,
    // achievementScore: 103,
    teamRank: "2 out of 4",
    leagueRank: 2789,
    class: "Sophomore",
    birthday: "1/1/2005",
    height: `6'4"`,
    weight: 194,
    university: "University of Buffalo",
    sport: "Basketball",
    hometown: "Delray Beach, FL",
    brandHistory: [
      {
        month: "April",
        value: 409.00
      },
      {
        month: "May",
        value: 399.00
      },
      {
        month: "June",
        value: 419.00
      }
    ],
  },
  {
    avatar: isaiah_adams_img,
    name: "Isaiah Adams",
    username: "isaiah.3_",
    jerseyNumber: "#3",
    brandScore: 447.00,
    athleticScore: 148.00,
    socialScore: 156.00,
    academicScore: 133.00,
    achievementScore: 10.00,
    teamRank: "1 out of 4",
    leagueRank: 2106,
    class: "Senior",
    birthday: "1/1/2005",
    height: `6'6"`,
    weight: 210,
    university: "University of Buffalo",
    sport: "Basketball",
    hometown: "Jacksonville, FL",
    brandHistory: [
      {
        month: "April",
        value: 437.00
      },
      {
        month: "May",
        value: 427.00
      },
      {
        month: "June",
        value: 447.00,
      }
    ],
  },
  {
    avatar: james_graham_img,
    name: "James Graham",
    username: "jimmybuckets_3",
    jerseyNumber: "#12",
    brandScore: 339.00,
    athleticScore: 148.00,
    socialScore: 56.00,
    academicScore: 133.00,
    achievementScore: 30.00,
    teamRank: "4 out of 4",
    leagueRank: 4067,
    class: "Junior",
    birthday: "6/18/2003",
    height: `6'7"`,
    weight: 208,
    university: "University of Buffalo",
    sport: "Basketball",
    hometown: "Milwaukee, WI",
    brandHistory: [
      {
        month: "April",
        value: 329.00
      },
      {
        month: "May",
        value: 319.00
      },
      {
        month: "June",
        value: 339.00
      }
    ],
  },
  {
    avatar: sy_chatman_img,
    name: "Sy Chatman",
    username: "sy_chatman1",
    jerseyNumber: "#1",
    brandScore: 367.00,
    athleticScore: 166.00,
    socialScore: 48.00,
    academicScore: 133.00,
    achievementScore: 20.00,
    teamRank: "3 out of 4",
    leagueRank: 3637,
    class: "5th Year",
    birthday: "6/18/2003",
    height: `6'8"`,
    weight: 227,
    university: "University of Buffalo",
    sport: "Basketball",
    hometown: "Minneapolis, MN",
    brandHistory: [
      {
        month: "April",
        value: 357.00
      },
      {
        month: "May",
        value: 347.00
      },
      {
        month: "June",
        value: 367.00
      },
    ],
  }
];
