import * as Mui from "@mui/material";

type Props = {
  onSkip?: () => void;
}

const SignUpFooter = (props: Props) => {
  return (
    <Mui.Stack spacing={3} width={"100%"} mt={4}>
      <Mui.Button
        size="medium"
        variant="contained"
        fullWidth
        type="submit"
      >
        Continue
      </Mui.Button>

      <Mui.Button size="medium" variant="text" fullWidth onClick={props.onSkip}>
        <Mui.Typography variant="body2" color={"paragraph.passive"}>
          Skip For Now
        </Mui.Typography>
      </Mui.Button>
    </Mui.Stack>
  )
}

export default SignUpFooter