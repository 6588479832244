import * as Mui from "@mui/material";
import background from "assets/background_dashboard.png";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CardPlayer } from "./components/CardPlayer";
import { IPublicUser, publicUsers } from "./data/PublicUsersData";

//  React Slick library css import
import SlickSlider from "@/compontents/slider/SlickSlider";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import HistogramPlayer from "./components/HistogramPlayer";
import PieChartPlayer from "./components/PieChartPlayer";

export const PublicDashboard = () => {
  const [userData, setUserData] = useState<IPublicUser | undefined>(undefined);

  const params = useParams();

  useEffect(() => {
    const username = params["username"];

    const user = publicUsers.find((element) => element.username === username);

    if (user) {
      setUserData(user);
    } else {
      setUserData(undefined);
    }

    return () => {
      setUserData(undefined);
    };
  }, []);

  return (
    <Mui.Container
      maxWidth="sm"
      sx={{
        py: 4,
        px: {
          xs: 0,
          md: "unset",
        },
        backgroundImage: `url(${background})`,
        backgroundRepeat: "repeat-y",
        backgroundSize: "cover",
      }}
    >
      <Mui.Stack spacing={8}>
        <SlickSlider>
          <CardPlayer userData={userData} />
          <CardPlayer userData={userData} />
          <CardPlayer userData={userData} />
        </SlickSlider>

        <Mui.Stack>
          <Mui.Typography
            variant="h4"
            textAlign={"center"}
            width={"100%"}
            color={"#006680"}
          >
            BRAND SCORE
          </Mui.Typography>

          <SlickSlider>
            <PieChartPlayer userData={userData} />
            <PieChartPlayer userData={userData} />
            <PieChartPlayer userData={userData} />
          </SlickSlider>
        </Mui.Stack>

        <Mui.Stack width={"100%"}>
          <Mui.Typography
            variant="h4"
            textAlign={"center"}
            width={"100%"}
            color={"#006680"}
          >
            SCORE HISTORY
          </Mui.Typography>

          <HistogramPlayer userData={userData} />
        </Mui.Stack>
      </Mui.Stack>
    </Mui.Container>
  );
};
