import { axiosInstance } from "@/axios/AxiosInstance"
import { IUser } from "@/types-dto/IUser";

export const LinkSocialService = {
  updateLink: (workPlatform: string) => {
    return axiosInstance.patch<IUser>('user/update/link', null, {
      params: {
        workPlatform,
      }
    });
  }
}