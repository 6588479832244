import Input from "@/compontents/input/Input";
import { useSnackbar } from "@/hooks/useSnakbar";
import {
  AuthenticationService,
  ISignInPayload,
} from "@/services/AuthenticationService";
import { reactMutations } from "@/utils/ReactQueries";
import * as Mui from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { AppSettings } from "compontents/app/AppSettings";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

interface InputsForm {
    emailAddress: string;
    password: string;
}
export const SignIn = () => {
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const { handleSubmit, control } = useForm<ISignInPayload>();

  const signinUserMutation = useMutation({
    mutationKey: [reactMutations.sigIn],
    mutationFn: AuthenticationService.signin,
    onSuccess: (response) => {
      navigate("/dashboard");

      if (
        response.phyllo_oauth_token_expired &&
        response.phyllo_oauth_token_expired.length > 0
      ) {
        response.phyllo_oauth_token_expired.forEach((element) => {
          snackbar.show(
            "warning",
            `OAuth token for ${element} expired, pleaese re-connect this account`
          );
        });
      }
    },
    onError: (error) => {
      snackbar.show("error", error.message);
    },
  });

  const onLogin = (data: ISignInPayload) => {
    signinUserMutation.mutate(data);
  };

  return (
    <form onSubmit={handleSubmit(onLogin)}>
      <Mui.Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: `calc(100vh - ${AppSettings.appbar.height})`,
        }}
      >
        <Mui.Grid
          container
          sx={{
            width: {
              xs: "100%",
              md: "50%",
              lg: "30%",
            },
          }}
          spacing={2}
        >
          <Mui.Grid item xs={12}>
            <Mui.Typography variant="h3" color="primary.main">
              Sign In
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid item xs={12}>
            <Input
              label="Username"
              name="username"
              type="text"
              control={control}
              rules={{
                required: "Field is required!",
              }}
              fullWidth
            />
          </Mui.Grid>
          <Mui.Grid item xs={12}>
            <Input
              label="Password"
              type="password"
              name="password"
              control={control}
              rules={{
                required: "Field is required!",
              }}
              fullWidth
            />
          </Mui.Grid>
          <Mui.Grid item xs={12} md={"auto"}>
            <Mui.Button variant="contained" fullWidth type="submit">
              Sign In
            </Mui.Button>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Container>
    </form>
  );
};
