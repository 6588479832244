import Input from "@/compontents/input/Input";
import { useLocalStorage } from "@/hooks/useLocalStorage";
import { LocalStorageItems } from "@/utils/LocalStorageItems";
import * as Mui from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import SignUpBody from "./components/SignUpBody";
import SignUpFooter from "./components/SignUpFooter";
import SignUpHeader from "./components/SignUpHeader";
import { useMemo } from "react";
import { IAcademicsFormInputs, IAthleteInfos } from "./types";

export const Academics = () => {
  const navigate = useNavigate();

  const { getItem, setItem } = useLocalStorage<IAthleteInfos>(
    LocalStorageItems.signUpInformation
  );

  const sports = useMemo(() => {
    return getItem().sports;
  }, []);

  const { handleSubmit, control, watch, setValue } =
    useForm<IAcademicsFormInputs>();

  const onSubmit = (data: IAcademicsFormInputs) => {
    const information = getItem();

    if (data && (data.actScore || data.gradePointAverage || data.satScore)) {
      information.academics = {
        actScore: parseFloat(data.actScore?.toString() || ""),
        gradePointAverage: parseFloat(data.gradePointAverage?.toString() || ""),
        satScore: parseFloat(data.satScore?.toString() || ""),
      };
      setItem(information);
    }

    navigate("/join-team/socials");
  };

  return (
    <Mui.Container maxWidth="xs">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Mui.Stack>
          <SignUpHeader
            title="Academics"
            subtitle="Tell us your academic information."
          />

          <SignUpBody>
            <Mui.Grid container>
              <Mui.Grid item xs={12}>
                <Input
                  name="gradePointAverage"
                  type="number"
                  fullWidth
                  label={"GRADE POINT AVERAGE"}
                  control={control}
                  rules={{
                    min: {
                      value: 0,
                      message: "Minimum value allowed is 0",
                    },
                    max: {
                      value: 4,
                      message: "Maximum value allowed is 4",
                    },
                  }}
                />
              </Mui.Grid>

              {(!sports || sports.level !== "College") && (
                <Mui.Grid item xs={12}>
                  <Input
                    name="satScore"
                    type="number"
                    fullWidth
                    label={"SAT SCORE"}
                    control={control}
                    rules={{
                      min: {
                        value: 400,
                        message: "This value must be greater than 400",
                      },
                      max: {
                        value: 1600,
                        message: "This value must be smaller than 1600",
                      },
                    }}
                  />
                </Mui.Grid>
              )}

              {(!sports || sports.level !== "College") && (
                <Mui.Grid item xs={12}>
                  <Input
                    name="actScore"
                    type="text"
                    fullWidth
                    label={"ACT SCORE"}
                    control={control}
                    rules={{
                      min: {
                        value: 1,
                        message: "This value must be greater than 1",
                      },
                      max: {
                        value: 36,
                        message: "This value must be smaller than 36",
                      },
                    }}
                  />
                </Mui.Grid>
              )}
            </Mui.Grid>
          </SignUpBody>

          <SignUpFooter onSkip={() => navigate("/join-team/socials")} />
        </Mui.Stack>
      </form>
    </Mui.Container>
  );
};
