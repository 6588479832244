import Input from "@/compontents/input/Input";
import { useSnackbar } from "@/hooks/useSnakbar";
import {
  AuthenticationService,
  ISignUpPayload,
} from "@/services/AuthenticationService";
import { reactMutations } from "@/utils/ReactQueries";
import { EnumUserCategory } from "@/utils/UserCategory";
import * as Mui from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useWindowSize } from "@uidotdev/usehooks";
import basketball from "assets/basketball.png";
import { AppSettings } from "compontents/app/AppSettings";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { brandleteTheme } from "theme/BrandleteTheme";
import SignUpHeader from "./components/SignUpHeader";
import SignUpBody from "./components/SignUpBody";

interface InputsForm extends ISignUpPayload {
  confirmPassword: string;
}

export const SetUpAccount = () => {
  const navigate = useNavigate();
  const windowSize = useWindowSize();

  const { userCategory } = useParams();
  const snackbar = useSnackbar();

  const { handleSubmit, control, formState } = useForm<InputsForm>({
    values: {
      email: "",
      confirmPassword: "",
      password: "",
      username: "",
      fullname: "",
    },
  });

  const signUpMutation = useMutation<any, any, any, any>({
    mutationKey: [reactMutations.signUp.setup],
    mutationFn: AuthenticationService.signup,
    onSuccess: () => {
      navigate("/join-team/athlete-information");
    },
    onError: (error) => {
      snackbar.show("error", error.message);
    },
  });

  const onCreateAccount = (formData: InputsForm) => {
    const { confirmPassword, ...data } = formData;
    signUpMutation.mutate(data);
  };

  return (
    <Mui.Container maxWidth="xs">
      <form onSubmit={handleSubmit(onCreateAccount)}>
        <Mui.Stack spacing={2} p={2}>
          <Mui.Stack spacing={2}>
            <Mui.Typography variant="h3">Setup Account</Mui.Typography>
            <Mui.Typography variant="body2">
              Already on the team?
              <Mui.Link sx={{ p: "5px" }} href="#sign-in">
                Sign in
              </Mui.Link>
            </Mui.Typography>
          </Mui.Stack>

          <SignUpBody>
            <Mui.Stack spacing={1}>
              <Input
                name="username"
                type="text"
                label={"USERNAME (MAKE IT UNIQUE)"}
                control={control}
                rules={{
                  maxLength: {
                    value: 30,
                    message: "Only 30 characters",
                  },
                  required: "Username is required",
                  pattern: {
                    value: /^[A-Za-z0-9 _.]*[A-Za-z0-9][A-Za-z0-9 _.]*$/i,
                    message:
                      "Username can only contain letters, numbers, periods, and underscores.",
                  },
                }}
                inputProps={{
                  fullWidth: true,
                }}
              />

              <Input
                name="fullname"
                type="text"
                label={"FULL NAME"}
                control={control}
                rules={{
                  maxLength: {
                    value: 50,
                    message: "Only 50 characters",
                  },
                  required: "Full name is required",
                  pattern: {
                    value: /^[A-Za-z0-9-`'"]{1,}(?: [A-Za-z0-9-`'"]+){0,2}$/g,
                    message: "Full name can only contain letters.",
                  },
                }}
                inputProps={{
                  fullWidth: true,
                }}
              />

              <Input
                label="EMAIL"
                name="email"
                control={control}
                rules={{
                  pattern: {
                    value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                    message: "Email format not correct",
                  },
                }}
                type="email"
                inputProps={{
                  fullWidth: true,
                }}
              />

              <Input
                label="PASSWORD"
                type="password"
                name="password"
                control={control}
                rules={{
                  minLength: {
                    value: 8,
                    message: "Password is to short",
                  },
                  maxLength: {
                    value: 32,
                    message: "Password is to long",
                  },
                  required: "Password is required",
                  pattern: {
                    value:
                      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/,
                    message: `Your password must be at least 8 characters long: 
                                                            - 1 uppercase,
                                                            - 1 lowercase character,
                                                            - 1 number,
                                                            - 1 special character`,
                  },
                }}
                inputProps={{
                  fullWidth: true,
                  sx: {
                    whiteSpace: "pre-line",
                  },
                }}
              />

              <Input
                label="CONFIRM PASSWORD"
                type="password"
                name="confirmPassword"
                control={control}
                rules={{
                  validate: (value, formValue) => {
                    if (value !== formValue.password) {
                      return "Password not matched";
                    }
                  },
                }}
                inputProps={{
                  fullWidth: true,
                }}
              />
            </Mui.Stack>
          </SignUpBody>

          <Mui.Button
            variant="contained"
            type="submit"
            size="medium"
            disabled={formState.submitCount > 0 && !formState.isValid}
          >
            Create Account
          </Mui.Button>
        </Mui.Stack>
      </form>
    </Mui.Container>
  );
};
