import { ThemeOptions, createTheme, responsiveFontSizes } from "@mui/material";
import createPalette, { Palette } from "@mui/material/styles/createPalette";
import createTypography from "@mui/material/styles/createTypography";

// declare module "@mui/material/Button" {
//   interface ButtonPropsVariantOverrides {
//     paper: true;
//   }
// }

declare module "@mui/material/styles" {
  interface Palette {
    paragraph: Palette["text"] & {
      passive: string
    };
  }

  interface PaletteOptions {
    paragraph?: PaletteOptions["text"] & {
      passive?: string
    };
  }
}

export const paletteOptions: Palette = createPalette({
  mode: "dark",
  primary: {
    main: "#00CCFF",
  },
  secondary: {
    main: "#00CCFF",
  },
  background: {
    default: "#09141A",
  },
  text: {
    primary: "#FFFFFF",
    secondary: "#EFEFEF",
  },
  paragraph: {
    primary: "#FFFFFF",
    secondary: "#EFEFEF",
    passive: "#999999"
  },
  info: {
    main: "#5cc9fa26",
    "100": "#006680"
  },
  error: {
    main: "#66FFD1",
  },
});

const typographyOptions = createTypography(paletteOptions, {
  allVariants: {
    fontFamily: "brandlete-regular",
  },
  fontSize: 16,
  h1: {
    fontFamily: "brandlete-bold",
    fontSize: "50px",
    fontWeight: "bold",
  },
  h2: {
    fontFamily: "brandlete-bold",
    fontSize: "40px",
    fontWeight: "bold",
  },
  h3: {
    fontFamily: "brandlete-bold",
    fontSize: "30px",
    fontWeight: "bold",
  },
  h4: {
    fontFamily: "brandlete-bold",
    fontSize: "20px",
    fontWeight: "bold",
  },
  body2: {
    fontFamily: "brandlete-regular",
    fontSize: "14px",
    // lineHeight: "20px",
  },
  caption: {
    fontSize: "10px",
  },
  subtitle1: {
    fontSize: "16px",
    lineHeight: "28px",
  },
  subtitle2: {
    fontSize: "12px",
  },
  button: {
    textTransform: "none",
  },
});

const themeOptions: ThemeOptions = {
  palette: paletteOptions,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "0px",
        },
      },
      variants: [
        {
          props: { size: "small", variant: "contained" },
          style: {
            borderWidth: "2px",
            backgroundColor: "#FFFFFF",
            borderColor: "#FFFFFF",
            color: paletteOptions.background.default,
            ":hover": {
              borderWidth: "2px",
              borderColor: paletteOptions.primary.main,
              backgroundColor: paletteOptions.primary.main,
              color: paletteOptions.background.default,
            },
          },
        },
        {
          props: { size: "small", variant: "outlined" },
          style: {
            borderWidth: "2px",
            borderColor: "#FFFFFF",
            color: "#FFFFFF",
            ":hover": {
              borderWidth: "2px",
              borderColor: paletteOptions.primary.main,
              backgroundColor: paletteOptions.primary.main,
              color: paletteOptions.background.default,
            },
          },
        },
        {
          props: { size: "large" },
          style: {
            borderWidth: "2px",
            ":hover": {
              borderWidth: "2px",
              borderColor: "#FFFFFF",
              backgroundColor: "#FFFFFF",
              color: paletteOptions.background.default,
            },
          },
        },
        {
          props: { size: "medium" },
          style: {
            borderWidth: "2px",
            ":hover": {
              borderWidth: "2px",
              borderColor: "#FFFFFF",
              backgroundColor: "#FFFFFF",
              color: paletteOptions.background.default,
            },
          },
        },
      ],
    },
    MuiPaper: {
      defaultProps: {
        sx: {
          padding: 1,
          // background: paletteOptions.secondary[500],
          // boxShadow: `3px 3px 30px -3px ${paletteOptions.secondary[300]}`,
        },
      },
    },
  },
  typography: typographyOptions,
  breakpoints: {},
};

let brandleteTheme = createTheme(themeOptions);
brandleteTheme = responsiveFontSizes(brandleteTheme);
export { brandleteTheme };

