import { paletteOptions } from "@/theme/BrandleteTheme";
import * as Mui from "@mui/material";
import { PropsWithChildren, useMemo, useState } from "react";
import Slider, { Settings } from "react-slick";

const sliderOptions = {
  palette: {
    dots: {
      active: "#00CCFF",
      unActive: "#006680",
    },
  },
  dots: {
    size: "10px",
  },
};

interface SliderDotProps {
  slideNumber: number;
  currSlide: number;
}

const SliderDot = (props: SliderDotProps) => {
  const isActive = props.slideNumber === props.currSlide;

  if (isActive) {
    return (
      <Mui.Box
        width={sliderOptions.dots.size}
        height={sliderOptions.dots.size}
        borderRadius={"50%"}
        bgcolor={sliderOptions.palette.dots.active}
      />
    );
  } else {
    return (
      <Mui.Box
        width={sliderOptions.dots.size}
        height={sliderOptions.dots.size}
        position={"relative"}
        sx={{
          ":before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            backgroundColor: sliderOptions.palette.dots.unActive,
            borderRadius: "50%",
          },
          ":after": {
            content: '""',
            position: "absolute",
            top: "20%",
            left: "20%",
            height: "60%",
            width: "60%",
            backgroundColor: paletteOptions.background.default,
            borderRadius: "50%",
          },
        }}
      />
    );
  }
};

interface Props extends PropsWithChildren, Settings {}

const SlickSlider = (props: Props) => {
  const [currSlide, setCurrSlide] = useState(0);

  return (
    <Mui.Box
      className="slider-container"
      sx={{
        background: "transparent",
      }}
    >
      <Slider
        slidesToShow={1}
        slidesToScroll={1}
        dots={true}
        arrows={false}
        afterChange={(currSlide) => setCurrSlide(currSlide)}
        customPaging={(index) => (
          <SliderDot slideNumber={index} currSlide={currSlide} />
        )}
      >
        {props.children}
      </Slider>
    </Mui.Box>
  );
};

export default SlickSlider;
