import { AuthenticationService } from "@/services/AuthenticationService";
import { reactMutations } from "@/utils/ReactQueries";
import { getAuthentication } from "@/utils/getAuthentication";
import * as MuiIcons from "@mui/icons-material";
import * as Mui from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import sneaker from "assets/sneaker2.svg";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { brandleteTheme } from "theme/BrandleteTheme";
import { AppSettings } from "./AppSettings";

const AppBarMenu = () => {
  return (
    <>
      <Mui.Grid item xs={"auto"}>
        <Mui.Button
          sx={{
            display: { xs: "block", md: "flex" },
            alignItems: "center",
          }}
        >
          <MuiIcons.Home />
          <Mui.Typography>Home</Mui.Typography>
        </Mui.Button>
      </Mui.Grid>

      <Mui.Grid item xs={"auto"}>
        <Mui.Button
          sx={{
            display: { xs: "block", md: "flex" },
            alignItems: "center",
          }}
        >
          <MuiIcons.Person />
          <Mui.Typography>Athletes</Mui.Typography>
        </Mui.Button>
      </Mui.Grid>

      <Mui.Grid item xs={"auto"}>
        <Mui.Button
          sx={{
            display: { xs: "block", md: "flex" },
            alignItems: "center",
          }}
        >
          <MuiIcons.BusinessCenter />
          <Mui.Typography>Brands</Mui.Typography>
        </Mui.Button>
      </Mui.Grid>

      <Mui.Grid item xs={"auto"}>
        <Mui.Button
          sx={{
            display: { xs: "block", md: "flex" },
            alignItems: "center",
          }}
        >
          <MuiIcons.Forum />
          <Mui.Typography>Inbox</Mui.Typography>
        </Mui.Button>
      </Mui.Grid>
    </>
  );
};

export const AppBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn: isAuthenticated } = getAuthentication();

  const [userMenuAnchorElement, setUserMenuAnchorElement] =
    useState<null | HTMLButtonElement>(null);

  const signoutMutation = useMutation({
    mutationKey: [reactMutations.signOut],
    mutationFn: AuthenticationService.signout,
    onSuccess: () => {
      navigate("");
    },
  });

  const signOut = () => {
    signoutMutation.mutate();
    setUserMenuAnchorElement(null);
  };

  const isLoggedIn =
    isAuthenticated &&
    location.pathname.toLowerCase() !== "/link-social" &&
    location.pathname.toLowerCase() !== "/brandlete-score" &&
    !location.pathname.toLowerCase().includes("join-team");

  const isScreenMd = Mui.useMediaQuery(brandleteTheme.breakpoints.up("md"));

  const showLogin =
    location.pathname !== "/sign-in" &&
    !location.pathname.toLowerCase().includes("join-team");

  const isTransparent = location.pathname.toLowerCase() === "/";

  if (isLoggedIn) {
    return (
      <>
        <Mui.AppBar
          component={"nav"}
          sx={{
            height: AppSettings.appbar.height,
            background: brandleteTheme.palette.background.default,
            border: "none",
            boxShadow: 0,
            color: "primary.main",
          }}
        >
          <Mui.Toolbar sx={{ height: "100%" }}>
            <Mui.Grid container alignItems="center" spacing={2}>
              <Mui.Grid item xs={"auto"} mr={"auto"}>
                <Link to={""}>
                  <img src={sneaker} height={"83px"} alt="not-found" />
                </Link>
              </Mui.Grid>

              {isScreenMd && <AppBarMenu />}

              <Mui.Grid item xs={"auto"} textAlign="end" ml={"auto"}>
                <>
                  <Mui.IconButton color="primary">
                    <MuiIcons.SearchRounded />
                  </Mui.IconButton>

                  <>
                    <Mui.IconButton
                      id="user-menu-button"
                      color="primary"
                      onClick={(event) =>
                        setUserMenuAnchorElement(event.currentTarget)
                      }
                    >
                      <MuiIcons.Person2 />
                    </Mui.IconButton>

                    <Mui.Menu
                      id="user-menu"
                      open={userMenuAnchorElement !== null}
                      onClose={() => setUserMenuAnchorElement(null)}
                      anchorEl={userMenuAnchorElement}
                      MenuListProps={{
                        "aria-labelledby": "user-menu-button",
                      }}
                    >
                      <Mui.MenuItem onClick={signOut}>Log out</Mui.MenuItem>
                    </Mui.Menu>
                  </>
                </>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Toolbar>
        </Mui.AppBar>

        <Mui.AppBar
          position="fixed"
          sx={{
            top: "auto",
            bottom: 0,
            display: { xs: "block", md: "none" },
            height: AppSettings.appbar.height,
            border: "none",
            boxShadow: 0,
            color: "primary.main",
            backgroundColor: Mui.alpha(
              brandleteTheme.palette.background.paper,
              0.8
            ),
            pt: "5px",
          }}
        >
          <Mui.Toolbar sx={{ height: "100%" }}>
            <Mui.Grid
              container
              alignItems="center"
              spacing={{ xs: 0, md: 2 }}
              textAlign="center"
              justifyContent={"center"}
            >
              <AppBarMenu />
            </Mui.Grid>
          </Mui.Toolbar>
        </Mui.AppBar>
      </>
    );
  }

  return (
    <>
      <Mui.AppBar
        component={"nav"}
        sx={{
          height: AppSettings.appbar.height,
          background: brandleteTheme.palette.background.default,
          color: "primary.main",
          ...(isTransparent && {
            background: "transparent",
            border: "none",
            boxShadow: 0,
          })
        }}
      >
        <Mui.Toolbar sx={{ height: "100%" }}>
          <Mui.Grid container alignItems="center" spacing={2}>
            <Mui.Grid item xs={"auto"} mr={"auto"}>
              <Link to={""}>
                <img src={sneaker} height={"83px"} alt="not-found" />
              </Link>
            </Mui.Grid>

            {showLogin && (
              <Mui.Grid item xs={"auto"} textAlign="end" ml={"auto"}>
                <Mui.Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    navigate("sign-in");
                  }}
                >
                  SIGN IN
                </Mui.Button>
              </Mui.Grid>
            )}
          </Mui.Grid>
        </Mui.Toolbar>
      </Mui.AppBar>
    </>
  );
};
