import { axiosInstance } from "@/axios/AxiosInstance";
import { LocalStorageItems } from "@/utils/LocalStorageItems";
import { getAuthentication } from "@/utils/getAuthentication";

declare var PhylloConnect: any;

export interface IPhylloConnectOptions {
  config: {
    clientDisplayName?: string, // the name of your app that you want the creators to see while granting access
    environment?: "sandbox" | "staging" | "production", // the mode in which you want to use the SDK,  `sandbox`, `staging` or `production`
    // userId: string, // the unique user_id parameter returned by Phyllo API when you create a user (see https://docs.getphyllo.com/docs/api-reference/reference/openapi.v1.yml/paths/~1v1~1users/post)
    // token: string,
    redirect?: boolean, // (optional) flag to indicate that you want to use the redirect flow, this is `false` by default
    redirectURL?: string, // the URL to which the creator should be redirected after completing their actions on the Connect SDK
    workPlatformId?: string, // (optional) the unique work_platform_id of a specific work platform, if you want the creator to skip the platform selection screen and just be able to connect just with a single work platform
  
  }
  onAccountConnect?: (accountId?: string, workplatformId?: string, userId?: string) => void;
  onAccountDisconnected?: (accountId?: string, workplatformId?: string, userId?: string) => void;
  onExit?: (reason?: string, userId?: string) => void;
  onConnectionFailure?: (reason?: string, workplatformId?: string, userId?: string) => void;
  onTokenExpired?: () => void;
}

export const PhylloService = {
  connect: (connectOptions: IPhylloConnectOptions) => {
    const { user } = getAuthentication();
    const phylloId = user.phyllo_id;
    const phylloSdkToken = user.phyllo_sdk_token;

    if (!phylloId || !phylloSdkToken) {
      console.log(`phyllo_id: ${phylloId} - phyllo_sdk_token: ${phylloSdkToken}`);
      console.error('Phyllo Id or Phyllo token is missing');
      return;
    }

    const config = {
      userId: phylloId,
      token: phylloSdkToken,
      clientDisplayName: "Brandlete",
      environment: "staging",
      redirect: false,
      ...connectOptions.config
    };

    const phylloConnect = PhylloConnect.initialize(config);

    phylloConnect.on(
      "accountConnected",
      (accountId: string, workplatformId: string, userId: string) => {
        console.log(
          `onAccountConnected: ${accountId}, ${workplatformId}, ${userId}`
        );
        connectOptions.onAccountConnect && connectOptions.onAccountConnect(accountId, workplatformId, userId);
      }
    );

    phylloConnect.on(
      "accountDisconnected",
      (accountId: string, workplatformId: string, userId: string) => {
        console.log(
          `onAccountDisconnected: ${accountId}, ${workplatformId}, ${userId}`
        );
        connectOptions.onAccountDisconnected && connectOptions.onAccountDisconnected(accountId, workplatformId, userId);
      }
    );

    phylloConnect.on("tokenExpired", async (userId: string) => {
      connectOptions.onTokenExpired && connectOptions.onTokenExpired();
    });

    phylloConnect.on("exit", (reason: string, userId: string) => {
      console.log(`onExit: ${reason}, ${userId}`);

      connectOptions.onExit && connectOptions.onExit(reason, userId);
    });

    phylloConnect.on(
      "connectionFailure",
      (reason: string, workplatformId: string, userId: string) => {
        console.log(
          `onConnectionFailure: ${reason}, ${workplatformId}, ${userId}`
        );

        connectOptions.onConnectionFailure && connectOptions.onConnectionFailure(reason, workplatformId, userId);

        alert("WorkPlatform connection failure reason: " + reason);
      }
    );

    phylloConnect.open();
  },
  getPhylloSDK: () => {
    return axiosInstance.get<string>('/user/phylloSDK');
  }
};
