import * as notistack from "notistack";

export const useSnackbar = () => {
  const snackbar = notistack.useSnackbar();

  const showSnackbar = (variant: notistack.VariantType, message: string) =>
    snackbar.enqueueSnackbar(message, {
      variant: variant,
    });

  return {
    show: showSnackbar,
  };
};
