import { IScoreDto } from "@/types-dto/IScoreDto";
import { reactQueries } from "@/utils/ReactQueries";
import { getAuthentication } from "@/utils/getAuthentication";
import * as MuiIcons from "@mui/icons-material";
import * as Mui from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { DashboardService } from "./DashboardService";

export const Dashboard = () => {
  const { user } = getAuthentication();

  const getDataQuery = useQuery<IScoreDto>({
    queryKey: [reactQueries.dashboard.getData],
    queryFn: DashboardService.getData,
    refetchOnWindowFocus: false,
  });

  return (
    <Mui.Container>
      <Mui.Grid container spacing={2}>
        <Mui.Grid item xs={12} md={4}>
          <Mui.Stack spacing={4}>
            <Mui.Paper>
              <Mui.Grid container alignItems={"center"} spacing={2}>
                <Mui.Grid item xs={"auto"}>
                  <Mui.Avatar
                    alt={user.username}
                    src="/static/images/avatar/1.jpg"
                    style={{ width: "70px", height: "70px" }}
                  />
                </Mui.Grid>

                <Mui.Grid item xs={"auto"}>
                  <Mui.Stack direction={"row"} alignItems={"center"}>
                    <Mui.Typography>{user.username}</Mui.Typography>

                    <MuiIcons.CheckCircle color="primary" sx={{ ml: 2 }} />
                  </Mui.Stack>
                  <Mui.Typography color={"primary"}>
                    @usercompany
                  </Mui.Typography>
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Paper>
          </Mui.Stack>
        </Mui.Grid>

        <Mui.Grid item xs={12} md={8}>
          <Mui.Grid container spacing={2}>
            <Mui.Grid item xs={6} md={3}>
              <Mui.Paper>
                <MuiIcons.WorkspacePremium color="primary" />

                <Mui.Stack direction={"row"}>
                  <Mui.Typography color="text.primary">
                    {getDataQuery.data?.score}
                  </Mui.Typography>
                  <Mui.Typography color="primary.700" fontWeight="bold">
                    /1000
                  </Mui.Typography>
                </Mui.Stack>

                <Mui.Typography variant="subtitle2" color={"primary"}>
                  Brandlete Score
                </Mui.Typography>
              </Mui.Paper>
            </Mui.Grid>

            <Mui.Grid item xs={6} md={3}>
              <Mui.Paper>
                <MuiIcons.Coronavirus color="success" />

                <Mui.Typography color="text.primary">
                  {getDataQuery.data?.totalReach}
                </Mui.Typography>

                <Mui.Typography variant="subtitle2" color={"success.main"}>
                  Total Reach
                </Mui.Typography>
              </Mui.Paper>
            </Mui.Grid>

            <Mui.Grid item xs={6} md={3}>
              <Mui.Paper>
                <MuiIcons.Group color="primary" />

                <Mui.Typography color="text.primary">
                  {getDataQuery.data?.followers}
                </Mui.Typography>

                <Mui.Typography variant="subtitle2" color={"primary.main"}>
                  Followers
                </Mui.Typography>
              </Mui.Paper>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Grid>
    </Mui.Container>
  );
};
