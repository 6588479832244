import Input from "@/compontents/input/Input";
import InputLabel from "@/compontents/input/InputLabel";
import * as MuiIcons from "@mui/icons-material";
import * as Mui from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import SignUpBody from "./components/SignUpBody";
import SignUpFooter from "./components/SignUpFooter";
import SignUpHeader from "./components/SignUpHeader";
import { LocalStorageItems } from "@/utils/LocalStorageItems";
import { useLocalStorage } from "@/hooks/useLocalStorage";
import {
  IAthleteInfos,
  ISportsFormInputs,
  levelList,
  sportsList,
} from "./types";

type Props = {};

const Sports = (props: Props) => {
  const navigate = useNavigate();

  const { getItem, setItem } = useLocalStorage<IAthleteInfos>(
    LocalStorageItems.signUpInformation
  );
  const [selectOpen, setSelectOpen] = useState(false);

  const { handleSubmit, control, setValue, watch } = useForm<ISportsFormInputs>(
    {
      defaultValues: {
        sports: [],
      },
    }
  );

  const onValid = (data: ISportsFormInputs) => {
    const infos = getItem();

    if (data && (data.sports.length > 0 || data.level)) {
      infos.sports = data;
      setItem(infos);
    }

    if (data.level === "Professional") {
      navigate("/join-team/socials");
    } else {
      navigate("/join-team/academics");
    }
  };

  return (
    <Mui.Container maxWidth="xs">
      <form onSubmit={handleSubmit(onValid)}>
        <Mui.Stack>
          <SignUpHeader
            title="Sports"
            subtitle="Tell us about the sports you play."
          />

          <SignUpBody>
            <Mui.Stack spacing={1}>
              <InputLabel label="What Sport(s) Do You Play?" />

              {watch("sports").map((sport, index) => {
                return (
                  <Input
                    key={`sport-${index}`}
                    name={`sport-${index}`}
                    type="text"
                    value={sport}
                    InputProps={{
                      endAdornment: (
                        <Mui.IconButton
                          onClick={() =>
                            setValue(
                              "sports",
                              watch("sports").filter(
                                (element) => element !== sport
                              )
                            )
                          }
                        >
                          <MuiIcons.Clear />
                        </Mui.IconButton>
                      ),
                    }}
                  />
                );
              })}

              {watch("sports").length < 3 && (
                <Input
                  name="sports"
                  type="text"
                  control={control}
                  select
                  value={""}
                  onChange={(event) => {
                    setValue("sports", [
                      ...watch("sports"),
                      event.target.value,
                    ]);
                  }}
                  SelectProps={{
                    open: selectOpen,
                    onOpen: () => setSelectOpen(true),
                    onClose: () => setSelectOpen(false),
                    IconComponent: (iconProps: { className: string }) => {
                      const isOpen =
                        iconProps.className.includes("MuiSelect-iconOpen");
                      return (
                        <Mui.IconButton
                          onClick={() => setSelectOpen(!selectOpen)}
                        >
                          {isOpen ? <MuiIcons.Clear /> : <MuiIcons.Add />}
                        </Mui.IconButton>
                      );
                    },
                  }}
                >
                  {sportsList
                    .filter((element) => !watch("sports").includes(element))
                    .map((element) => (
                      <Mui.MenuItem key={`sport-${element}-2`} value={element}>
                        {element}
                      </Mui.MenuItem>
                    ))}
                </Input>
              )}

              <Input
                label="What Level Do you Play At?"
                name="level"
                type="text"
                select
                value={watch("level")}
                onChange={(event) => {
                  setValue(
                    "level",
                    event.target.value as (typeof levelList)[number]
                  );
                }}
              >
                {levelList.map((level, index) => (
                  <Mui.MenuItem key={`${level}-${index}`} value={level}>
                    {level}
                  </Mui.MenuItem>
                ))}
              </Input>
            </Mui.Stack>
          </SignUpBody>

          <SignUpFooter onSkip={() => navigate("/join-team/academics")} />
        </Mui.Stack>
      </form>
    </Mui.Container>
  );
};

export default Sports;
