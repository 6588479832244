import * as Mui from "@mui/material";

type Props = {
  label?: string;
}

const InputLabel = (props: Props) => {
  return (
    <Mui.InputLabel>
      <Mui.Typography variant="caption">{props.label}</Mui.Typography>
    </Mui.InputLabel>
  )
}

export default InputLabel