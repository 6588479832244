import Input from "@/compontents/input/Input";
import { useLocalStorage } from "@/hooks/useLocalStorage";
import { LocalStorageItems } from "@/utils/LocalStorageItems";
import * as Mui from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import SignUpBody from "./components/SignUpBody";
import SignUpFooter from "./components/SignUpFooter";
import SignUpHeader from "./components/SignUpHeader";
import { IAthleteInfos, IInformationFormInputs } from "./types";
import dayjs from "dayjs";


const AthleteInformation = () => {
  const navigate = useNavigate();

  const { getItem, setItem } = useLocalStorage<IAthleteInfos>(
    LocalStorageItems.signUpInformation
  );

  const { control, handleSubmit, setValue, watch } = useForm<IInformationFormInputs>();

  const onValid = (data: IInformationFormInputs) => {
    const infos = getItem();
    if (data && (data.dateofbirth || data.feet || data.inches || data.weight)) {
      infos.information = data;
      setItem(infos);
    }

    navigate("/join-team/sports");
  };

  const onChangeMinMax = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof IInformationFormInputs,
    min: number = 0,
    max: number = 999
  ) => {
    const value = Number(event.currentTarget.value);

    if (value > max) {
      return;
    } else if (value < min) {
      setValue(field, 0);
      return;
    }

    if (!value) {
      setValue(field, 0);
      return;
    }

    setValue(field, value);
  };

  return (
    <Mui.Container maxWidth="xs">
      <form onSubmit={handleSubmit(onValid)}>
        <Mui.Stack p={2}>
          <SignUpHeader
            title="Athlete Information"
            subtitle="Enter your age, height and weight."
          />

          <SignUpBody>
            <Mui.Grid container alignItems={"end"} spacing={2}>
              <Mui.Grid item xs={12}>
                <Input
                  name="dateofbirth"
                  control={control}
                  label={"Date Of Birth"}
                  type="date"
                  format="MM-DD-YYYY"
                  showDaysOutsideCurrentMonth={true}
                  maxDate={dayjs()}
                />
              </Mui.Grid>

              <Mui.Grid item xs={12} sm={6}>
                <Input
                  name="feet"
                  type="number"
                  control={control}
                  label={"Height"}
                  fullWidth
                  placeholder="Feet"
                  onChange={(event) => onChangeMinMax(event, "feet")}
                  value={watch("feet")}
                />
              </Mui.Grid>

              <Mui.Grid item xs={12} sm={6}>
                <Input
                  name="inches"
                  type="number"
                  control={control}
                  sx={{ mt: "auto" }}
                  fullWidth
                  placeholder="Inches"
                  onChange={(event) => onChangeMinMax(event, "inches")}
                  value={watch("inches")}
                />
              </Mui.Grid>

              <Mui.Grid item xs={12}>
                <Input
                  name="weight"
                  type="number"
                  control={control}
                  label={"weight"}
                  fullWidth
                  placeholder="Lbs"
                  onChange={(event) => onChangeMinMax(event, "weight")}
                  value={watch("weight")}
                />
              </Mui.Grid>
            </Mui.Grid>
          </SignUpBody>

          <SignUpFooter onSkip={() => navigate("/join-team/sports")} />
        </Mui.Stack>
      </form>
    </Mui.Container>
  );
};

export default AthleteInformation;
