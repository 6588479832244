import * as Mui from "@mui/material";
import { ResponsiveLine } from "@nivo/line";
import { IPublicUser } from "../data/PublicUsersData";
import Cards from "@/compontents/cards/Cards";
import { useMemo } from "react";

interface Props {
  userData?: IPublicUser;
}

const HistogramPlayer = (props: Props) => {
  const [min, max, dif] = useMemo(() => {
    if (!props.userData) {
      return [0, 0, 0];
    }

    let min = props.userData.brandHistory[0].value;
    let max = 0;
    for (let element of props.userData.brandHistory) {
      if (element.value < min) {
        min = element.value;
        continue;
      }

      if (element.value > max) {
        max = element.value;
        continue;
      }
    }

    return [min, max, max - min];
  }, [props.userData]);

  if (!props.userData) {
    return null;
  }

  const lineData = [
    {
      id: "yourScore",
      color: "#00FFB3",
      data: props.userData.brandHistory.map((score) => {
        return {
          x: score.month,
          y: score.value,
        };
      }),
    },
  ];

  const gridYValues = [
    min - dif / 4,
    min,
    min + dif / 4,
    min + dif / 2,
    max - dif / 4,
    max,
    max + dif / 4,
  ];

  return (
    <Cards type="dark-glow">
      <Mui.CardContent>
        <Mui.Box height={"400px"}>
          <ResponsiveLine
            data={lineData}
            margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
            lineWidth={5}
            curve="linear"
            animate={true}
            gridYValues={gridYValues}
            yScale={{
              type: "linear",
              max: max + dif/4,
              min: min - dif/4,
            }}
            colors={{
              datum: "color",
            }}
            enablePoints={true}
            enableGridX={false}
            axisLeft={null}
            axisRight={{
              renderTick: (props) => {
                const isMax = props.value === max;
                return (
                  <g
                    transform={`translate(${props.x + (isMax ? 5 : 10)}, ${
                      props.y
                    })`}
                    style={{ opacity: props.opacity }}
                  >
                    <text
                      fill={isMax ? "#00FFB3" : "#006680"}
                      stroke="none"
                      fontSize={isMax ? 18 : 10}
                      fontWeight={isMax ? 700 : 400}
                      fontFamily="brandlete-regular"
                    >
                      {props.value}
                    </text>
                  </g>
                );
              },
              tickValues: gridYValues,
            }}
            axisBottom={{
              renderTick: (props) => {
                return (
                  <g
                    transform={`translate(${props.x}, ${props.y + 20})`}
                    style={{ opacity: props.opacity }}
                  >
                    <text
                      fill={"#006680"}
                      stroke="none"
                      fontSize={10}
                      fontWeight={400}
                      fontFamily="brandlete-regular"
                    >
                      {props.value}
                    </text>
                  </g>
                );
              },
            }}
            isInteractive={false}
            useMesh={true}
            theme={{
              grid: {
                line: {
                  stroke: "#0066801A",
                  strokeWidth: "1px",
                },
              },
            }}
          />
        </Mui.Box>
      </Mui.CardContent>
    </Cards>
  );
};

export default HistogramPlayer;
