import * as Mui from "@mui/material";
import * as d3 from "d3";
import { IPublicUser } from "../data/PublicUsersData";
import { useEffect, useRef } from "react";

interface IDataType {
  label: string;
  value?: number;
  color: string;
}

const arcGenerator = d3
  .arc<d3.PieArcDatum<IDataType>>()
  .innerRadius(100)
  .outerRadius(200);

interface ArcProps {
  id: string;
  data: d3.PieArcDatum<IDataType>;
}

const Arc = (props: ArcProps) => {
  return (
    <g key={props.id} z={10}>
      <path
        id={`${props.id}`}
        d={arcGenerator(props.data) || undefined}
        fill={props.data.data.color}
      />

      <text
        id={`text-id-${props.id}`}
        textAnchor="middle"
        dy={-12}
        fill="#006680"
        stroke="#09141A"
        strokeWidth={5}
        paintOrder={"stroke fill"}
        fontSize={20}
      >
        <textPath startOffset={"25%"} xlinkHref={`#${props.id}`}>
          {props.data.data.label}
        </textPath>
      </text>
    </g>
  );
};

interface PieProps {
  data: IDataType[];
  total: number;
}

const Pie = (props: PieProps) => {
  const createPie = d3
    .pie<IDataType>()
    .value((d) => d.value || 0)
    .sort(null);

  const data = createPie(props.data);

  console.log(props.data);
  const cx = 300,
    cy = 250,
    radius = 215;

  const dPath = `
            M ${cx}, ${cy}
            m ${radius}, 0
            a ${radius},${radius} 0 1,1 -${radius * 2},0
            a ${radius},${radius} 0 1,1  ${radius * 2},0`;

  return (
    <svg width={"100%"} height={500}>
      <path
        id="lines-path-id"
        d={dPath}
        stroke="#006680"
        fill="transparent"
        strokeWidth={20}
        strokeDasharray={"1 10"}
      />

      <text
        id={`text-id-total`}
        textAnchor="middle"
        fill="#006680"
        stroke="#09141A"
        strokeWidth={10}
        dy={5}
        paintOrder={"stroke fill"}
        fontSize={20}
        transform={`rotate(180 ${cx} ${cy})`}
      >
        <textPath
          startOffset={"25%"}
          xlinkHref={`#lines-path-id`}
          letterSpacing={5}
        >
          850
        </textPath>
      </text>

      <g transform={`translate(${300} ${250})`}>
        {data.map((d, i) => (
          <Arc id={`arc-${i}-${d.data.label}`} data={d} />
        ))}
      </g>
    </svg>
  );
};

interface Props {
  userData?: IPublicUser;
}

const PieChartPlayer = (props: Props) => {
  if (!props.userData) {
    return null;
  }

  const data: IDataType[] = [
    {
      label: "Academics",
      value: props.userData.academicScore,
      color: "#7AFFD7",
    },
    {
      label: "Achievements",
      value: props.userData.achievementScore,
      color: "#003A48",
    },
    {
      label: "Socials",
      value: props.userData.socialScore,
      color: "#BCFFEB",
    },
    {
      label: "Athletics",
      value: props.userData.athleticScore,
      color: "#00FFB3",
    },
  ];

  return (
    <Mui.Box position={"relative"}>
      <Pie data={data} total={props.userData.brandScore} />

      <Mui.Box position={"absolute"} top={"45%"} left={"45%"}>
        <Mui.Typography color={"#00FFB3"} fontSize={"51px !important"} lineHeight={"normal"}>{props.userData.brandScore}</Mui.Typography>
        <Mui.Typography color={"#006680"} fontSize={"12px !important"} marginLeft={"5px"}>out of 850</Mui.Typography>
      </Mui.Box>
    </Mui.Box>
  );
};

export default PieChartPlayer;
