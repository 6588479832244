import { usePhylloService } from "@/phylloSDKService/hooks/usePhylloService";
import * as MuiIcons from "@mui/icons-material";
import * as Mui from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { AppSettings } from "compontents/app/AppSettings";
import {
  EnumPhylloWorkPlatformNames,
  EnumPhylloWorkPlatforms,
} from "phylloSDKService/phylloWorkPlatforms";
import { PropsWithChildren, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { brandleteTheme } from "theme/BrandleteTheme";
import { LinkSocialService } from "./LinkSocialService";
import { reactMutations } from "@/utils/ReactQueries";
import { IUser } from "@/types-dto/IUser";
import { LocalStorageItems } from "@/utils/LocalStorageItems";

const SocialButton = (
  props: {
    active: boolean;
    handleClickActivation: (
      type: "facebook" | "instagram" | "twitter" | "tiktok"
    ) => void;
  } & PropsWithChildren
) => {
  return (
    <Mui.Button
      size="medium"
      fullWidth
      onClick={() => props.handleClickActivation("tiktok")}
      sx={{
        backgroundColor: props.active
          ? `${brandleteTheme.palette.primary.main} !important`
          : "black",
        color: props.active ? "black" : brandleteTheme.palette.primary.main,
      }}
    >
      <Mui.Typography
        variant="body2"
        color={props.active ? "black" : "primary.main"}
      >
        {props.children}
      </Mui.Typography>

      {props.active && (
        <MuiIcons.CheckCircleOutline
          fontSize="small"
          sx={{
            position: "absolute",
            right: 10,
          }}
        />
      )}
    </Mui.Button>
  );
};

export const LinkSocial = () => {
  const navigate = useNavigate();

  const PhylloService = usePhylloService();

  const [active, setActive] = useState<{ [workPlatform: string]: boolean }>({});

  useEffect(() => {
    const userData: IUser = JSON.parse(localStorage.getItem(LocalStorageItems.user) || "");
    const connectedAccounts = userData.phyllo_connected_accounts;
    
    const newActive = {
      [EnumPhylloWorkPlatformNames.FACEBOOK]: connectedAccounts?.find((val) => val.toLowerCase() === EnumPhylloWorkPlatformNames.FACEBOOK.toLowerCase()) ? true : false,
      [EnumPhylloWorkPlatformNames.INSTAGRAM]: connectedAccounts?.find((val) => val.toLowerCase() === EnumPhylloWorkPlatformNames.INSTAGRAM.toLowerCase()) ? true : false,
      [EnumPhylloWorkPlatformNames.TIKTOK]: connectedAccounts?.find((val) => val.toLowerCase() === EnumPhylloWorkPlatformNames.TIKTOK.toLowerCase()) ? true : false,
      [EnumPhylloWorkPlatformNames.TWITTER]: connectedAccounts?.find((val) => val.toLowerCase() === EnumPhylloWorkPlatformNames.TWITTER.toLowerCase()) ? true : false,
    };
    setActive(newActive);
  }, []);

  const linkSocialMutation = useMutation({
    mutationKey: [reactMutations.updateLink],
    mutationFn: LinkSocialService.updateLink,
    onSuccess: (user) => {
      const connectedAccounts = user.phyllo_connected_accounts;

      localStorage.setItem(LocalStorageItems.user, JSON.stringify(user));

      if (connectedAccounts) {
        const newActive: {[key: string]: boolean} = {};
        for (let connectedPlatform of connectedAccounts) {
          newActive[connectedPlatform] = true;
        }

        setActive({
          ...active,
          ...newActive,
        });
      }
    },
  });

  const onChangeConnection = (
    type: EnumPhylloWorkPlatformNames,
    isActive: boolean
  ) => {
    linkSocialMutation.mutate(type);
  };

  const handleClickActivation = (type: EnumPhylloWorkPlatformNames) => {
    if (type === EnumPhylloWorkPlatformNames.FACEBOOK) {
      PhylloService.connect({
        config: {
          workPlatformId: EnumPhylloWorkPlatforms.FACEBOOK,
        },
        onAccountConnect: () => onChangeConnection(type, true),
        onAccountDisconnected: () => onChangeConnection(type, false),
      });
    } else if (type === EnumPhylloWorkPlatformNames.INSTAGRAM) {
      PhylloService.connect({
        config: {
          workPlatformId: EnumPhylloWorkPlatforms.INSTAGRAM,
        },
        onAccountConnect: () => onChangeConnection(type, true),
        onAccountDisconnected: () => onChangeConnection(type, false),
      });
    } else if (type === EnumPhylloWorkPlatformNames.TIKTOK) {
      PhylloService.connect({
        config: {
          workPlatformId: EnumPhylloWorkPlatforms.TIKTOK,
        },
        onAccountConnect: () => onChangeConnection(type, true),
        onAccountDisconnected: () => onChangeConnection(type, false),
      });
    } else if (type === EnumPhylloWorkPlatformNames.TWITTER) {
      PhylloService.connect({
        config: {
          workPlatformId: EnumPhylloWorkPlatforms.TWITTER,
        },
        onAccountConnect: () => onChangeConnection(type, true),
        onAccountDisconnected: () => onChangeConnection(type, false),
      });
    }
  };

  const onContinue = () => {
    navigate("/brandlete-score");
  };

  const onSkip = () => {
    navigate("/");
  };

  return (
    <Mui.Container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: `calc(100vh - ${AppSettings.appbar.height})`,
      }}
    >
      <Mui.Grid container spacing={2} textAlign="center">
        <Mui.Grid item xs={12}>
          <Mui.Typography variant="h3" color="primary.main">
            Link your social
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item xs={12}>
          <Mui.Typography variant="body2" color="text.secondary">
            Linking you social accounts is important for calculating your
            influence score.
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item xs={12} md={8} mx={"auto"}>
          <SocialButton
            active={active[EnumPhylloWorkPlatformNames.FACEBOOK]}
            handleClickActivation={() =>
              handleClickActivation(EnumPhylloWorkPlatformNames.FACEBOOK)
            }
          >
            Facebook
          </SocialButton>
        </Mui.Grid>

        <Mui.Grid item xs={12} md={8} mx={"auto"}>
          <SocialButton
            active={active[EnumPhylloWorkPlatformNames.INSTAGRAM]}
            handleClickActivation={() =>
              handleClickActivation(EnumPhylloWorkPlatformNames.INSTAGRAM)
            }
          >
            Instagram
          </SocialButton>
        </Mui.Grid>

        <Mui.Grid item xs={12} md={8} mx={"auto"}>
          <SocialButton
            active={active[EnumPhylloWorkPlatformNames.TWITTER]}
            handleClickActivation={() =>
              handleClickActivation(EnumPhylloWorkPlatformNames.TWITTER)
            }
          >
            Twitter / X
          </SocialButton>
        </Mui.Grid>

        <Mui.Grid item xs={12} md={8} mx={"auto"}>
          <SocialButton
            active={active[EnumPhylloWorkPlatformNames.TIKTOK]}
            handleClickActivation={() =>
              handleClickActivation(EnumPhylloWorkPlatformNames.TIKTOK)
            }
          >
            Tik Tok
          </SocialButton>
        </Mui.Grid>

        <Mui.Grid item xs={12} md={8} mx={"auto"}>
          <Mui.Button
            variant="contained"
            onClick={onContinue}
            disabled={
              !active[EnumPhylloWorkPlatformNames.FACEBOOK] &&
              !active[EnumPhylloWorkPlatformNames.INSTAGRAM] &&
              !active[EnumPhylloWorkPlatformNames.TIKTOK] &&
              !active[EnumPhylloWorkPlatformNames.TWITTER]
            }
          >
            Continue
          </Mui.Button>
        </Mui.Grid>
        <Mui.Grid item xs={12} md={8} mx={"auto"}>
          <Mui.Button
            variant="outlined"
            sx={{ border: "0px", ":hover": { border: "0px" } }}
            onClick={onSkip}
          >
            Skip for now
          </Mui.Button>
        </Mui.Grid>
      </Mui.Grid>
    </Mui.Container>
  );
};
