import dayjs from "dayjs";

export const sportsList = [
  "Basketball",
  "Baseball",
  "Football",
  "Gymnastics",
  "Ice Hockey",
  "Lacrosse",
  "Soccer",
  "Softball",
  "Track and Field",
  "Volleyball",
] as const;

export const levelList = ["High School", "College", "Professional"] as const;

export interface IInformationFormInputs {
  dateofbirth: dayjs.Dayjs;
  feet: number;
  inches: number;
  weight: number;
};

export interface ISportsFormInputs {
  sports: string[];
  level: typeof levelList[number];
}

export interface IAcademicsFormInputs {
  gradePointAverage?: number;
  satScore?: number;
  actScore?: number;
}

export interface ISocialsFormInputs {
  instagram: string;
  facebook: string;
  tiktok: string;
  twitter: string;
}

export interface IAthleteInfos {
  information?: IInformationFormInputs;
  sports?: ISportsFormInputs;
  academics?: IAcademicsFormInputs;
  socials?: ISocialsFormInputs;
}