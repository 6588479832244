import * as Mui from "@mui/material";
import SignUpHeader from "./components/SignUpHeader";
import SignUpBody from "./components/SignUpBody";
import { useForm } from "react-hook-form";
import Input from "@/compontents/input/Input";
import SignUpFooter from "./components/SignUpFooter";
import { useNavigate } from "react-router-dom";
import { IAthleteInfos, ISocialsFormInputs } from "./types";
import { useMutation } from "@tanstack/react-query";
import { SignUpService } from "./SignUpService";
import { reactMutations } from "@/utils/ReactQueries";
import { useLocalStorage } from "@/hooks/useLocalStorage";
import { LocalStorageItems } from "@/utils/LocalStorageItems";

type Props = {};

const Socials = (props: Props) => {
  const navigate = useNavigate();

  const { getItem, setItem } = useLocalStorage<IAthleteInfos>(
    LocalStorageItems.signUpInformation
  );
  const { control, handleSubmit } = useForm<ISocialsFormInputs>();

  const signupMutation = useMutation({
    mutationKey: [reactMutations.signUp.saveInformation],
    mutationFn: SignUpService.saveUserInformation,
  });

  const onValid = (data: ISocialsFormInputs) => {
    const athletesInfo = getItem();

    if (
      data &&
      (data.facebook || data.instagram || data.tiktok || data.twitter)
    ) {
      athletesInfo.socials = data;
    }

    if (
      athletesInfo.academics ||
      athletesInfo.information ||
      athletesInfo.socials ||
      athletesInfo.sports
    ) {
      signupMutation.mutate(athletesInfo);
    }

    // reset athletes information
    setItem({});

    navigate("/dashboard");
  };

  const onSkip = () => {
    const athletesInfo = getItem();

    if (athletesInfo) {
      if (
        athletesInfo.academics ||
        athletesInfo.information ||
        athletesInfo.sports
      ) {
        signupMutation.mutate(athletesInfo);
      }
    }

    // reset athletes information
    setItem({});

    navigate("/dashboard");
  };

  return (
    <Mui.Container maxWidth="xs">
      <form onSubmit={handleSubmit(onValid)}>
        <Mui.Stack>
          <SignUpHeader
            title="Socials"
            subtitle="Add your social media accounts."
          />

          <SignUpBody>
            <Mui.Stack>
              <Input
                name="instagram"
                type="text"
                control={control}
                label="INSTAGRAM Username"
                placeholder="@username"
                InputProps={{
                  startAdornment: (
                    <Mui.Typography variant="body2" color={"paragraph.passive"}>
                      Instagram.com/
                    </Mui.Typography>
                  ),
                }}
              />

              <Input
                name="facebook"
                type="text"
                control={control}
                label="FACEBOOK Username"
                placeholder="@username"
                InputProps={{
                  startAdornment: (
                    <Mui.Typography variant="body2" color={"paragraph.passive"}>
                      Facebook.com/
                    </Mui.Typography>
                  ),
                }}
              />

              <Input
                name="tiktok"
                type="text"
                control={control}
                label="TIKTOK Username"
                placeholder="@username"
                InputProps={{
                  startAdornment: (
                    <Mui.Typography variant="body2" color={"paragraph.passive"}>
                      tiktok.com/
                    </Mui.Typography>
                  ),
                }}
              />

              <Input
                name="twitter"
                type="text"
                control={control}
                label="TWITTER / X Username"
                placeholder="@username"
                InputProps={{
                  startAdornment: (
                    <Mui.Typography variant="body2" color={"paragraph.passive"}>
                      twitter.com/
                    </Mui.Typography>
                  ),
                }}
              />
            </Mui.Stack>
          </SignUpBody>

          <SignUpFooter onSkip={onSkip} />
        </Mui.Stack>
      </form>
    </Mui.Container>
  );
};

export default Socials;
