import * as Mui from "@mui/material";
import { useEffect, useState } from "react";
import { ISetting, SettingsService } from "../SettingsService";
import { useMutation, useQuery } from "@tanstack/react-query";
import { reactMutations, reactQueries } from "@/utils/ReactQueries";
import { useSnackbar } from "@/hooks/useSnakbar";

type FormulaNames = "SCORING" | "TOTAL_REACH";

const ScoringFormulaSettings = () => {
  const snackbar = useSnackbar();

  const [formulas, setFormulas] = useState({
    scoring: "",
    reach: "",
  });
  const [scoringFormula, setScoringFormula] = useState<ISetting>();
  const [reachFormula, setReachFormula] = useState<ISetting>();

  const getDataQuery = useQuery<ISetting[]>({
    queryKey: [reactQueries.settings.scoring],
    queryFn: () => SettingsService.getByName(["SCORING", "TOTAL_REACH"]),
    refetchOnWindowFocus: false,
  });

  const updateSetting = useMutation({
    mutationKey: [reactMutations.settings.scoring],
    mutationFn: SettingsService.update,
    onSuccess: (response) => {
      if (response.name === "SCORING" && scoringFormula) {
        setScoringFormula(response);
        snackbar.show("success", "Scoring formula updated successfully!");
      } else if (response.name === "TOTAL_REACH" && reachFormula) {
        setReachFormula(response);
        snackbar.show("success", "Total reach formula updated successfully!");
      }
    },
    onError: (error) => {
      snackbar.show("error", error.message);
    },
  });

  useEffect(() => {
    if (getDataQuery.data) {
      const scoring = getDataQuery.data.find(
        (element) => element.name === "SCORING"
      );
      const reach = getDataQuery.data.find(
        (element) => element.name === "TOTAL_REACH"
      );
      setScoringFormula(scoring);
      setReachFormula(reach);
      setFormulas({
        scoring: scoring?.value || "",
        reach: reach?.value || "",
      });
    }
  }, [getDataQuery.data]);

  const onReset = (name: FormulaNames) => {
    if (name === "SCORING") {
      setFormulas({
        ...formulas,
        scoring: scoringFormula?.value || "",
      });
    } else if (name === "TOTAL_REACH") {
      setFormulas({
        ...formulas,
        reach: reachFormula?.value || "",
      });
    }
  };

  const onUpdate = (name: FormulaNames) => {
    if (name === "SCORING" && scoringFormula) {
      updateSetting.mutate({
        ...scoringFormula,
        value: formulas.scoring,
      });
    } else if (name === "TOTAL_REACH" && reachFormula) {
      updateSetting.mutate({
        ...reachFormula,
        value: formulas.reach,
      });
    }
  };

  return (
    <Mui.Stack
      justifyContent={"center"}
      alignItems={"center"}
      padding={2}
      spacing={2}
    >
      {/* INSTRUCTIONS */}
      <Mui.Stack maxWidth={{ xs: "100%", md: "90%", lg: "80%" }}>
        <Mui.Box>
          <Mui.Typography variant="h1" textAlign={"center"}>
            RULES
          </Mui.Typography>
          <Mui.Typography variant="body2" whiteSpace={"pre"}>
            {`
            Use any of the below objects data inside the formulas. The below shown objects are already loaded so the formulas need only to specify from which one to use it.
            For example to access number of followers can be done by getting it from profile data "profile.reputation.follower_count".
          `}
          </Mui.Typography>
        </Mui.Box>

        <Mui.Box>
          <Mui.Typography variant="h3" textAlign={"center"}>
            Functions
          </Mui.Typography>
          <Mui.Typography variant="body2" whiteSpace={"pre"}>
            {`
            There is only one function at the moment that can be used and that is 'sumof()'.
            This function should only be used when there are iterations to be made, so it can calculate the sum of certain variables inside an array of objects.
            It takes 2 parameters inside the function sumof(object, variable):
                - object: the array of objects to iterate from.
                - variable: the name of the variable to be summed from. Write directly the variable from the objects inside.
            
            For example: "sumof(contents, engagement.reach_organic_count)"
          `}
          </Mui.Typography>
        </Mui.Box>

        <Mui.Box>
          <Mui.Typography variant="h3" textAlign={"center"}>
            Operators
          </Mui.Typography>
          <Mui.Typography variant="body2" whiteSpace={"pre"}>
            {`
            There are 2 types of operators that can be used:
                1- Mathematical operators like (+-/*)
                2- Logical operators like "AND" -> (&&), "OR" -> (||)
          `}
          </Mui.Typography>
        </Mui.Box>
      </Mui.Stack>

      {/* FORMULAS */}
      <Mui.Grid container justifyContent={"center"} spacing={2}>
        <Mui.Grid item xs={0} md={1} lg={2} />
        <Mui.Grid item xs={12} md={3} lg={2}>
          <Mui.FormLabel>Scoring</Mui.FormLabel>
        </Mui.Grid>
        <Mui.Grid item xs={12} md={7} lg={6}>
          <Mui.Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Mui.TextareaAutosize
              name="scoring"
              style={{ width: "100%" }}
              value={formulas.scoring}
              onChange={(event) =>
                setFormulas({ ...formulas, scoring: event.currentTarget.value })
              }
            />

            <Mui.Stack direction={"row"}>
              <Mui.Button onClick={() => onReset("SCORING")}>Reset</Mui.Button>
              <Mui.Button
                variant="contained"
                onClick={() => onUpdate("SCORING")}
              >
                Update
              </Mui.Button>
            </Mui.Stack>
          </Mui.Stack>
        </Mui.Grid>
        <Mui.Grid item xs={0} md={1} lg={2} />

        <Mui.Grid item xs={12} md={3} lg={2}>
          <Mui.FormLabel>Total reach</Mui.FormLabel>
        </Mui.Grid>
        <Mui.Grid item xs={12} md={7} lg={6}>
          <Mui.Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Mui.TextareaAutosize
              name="total_reach"
              style={{ width: "100%" }}
              value={formulas.reach}
              onChange={(event) =>
                setFormulas({ ...formulas, reach: event.currentTarget.value })
              }
            />

            <Mui.Stack direction={"row"}>
              <Mui.Button onClick={() => onReset("TOTAL_REACH")}>
                Reset
              </Mui.Button>
              <Mui.Button
                variant="contained"
                onClick={() => onUpdate("TOTAL_REACH")}
              >
                Update
              </Mui.Button>
            </Mui.Stack>
          </Mui.Stack>
        </Mui.Grid>
      </Mui.Grid>

      {/* OBJECTS STRUCTURE */}
      <Mui.Stack width={"100%"}>
        <Mui.Accordion>
          <Mui.AccordionSummary>Profile</Mui.AccordionSummary>
          <Mui.AccordionDetails>
            <Mui.Typography whiteSpace={"pre"}>
              {`{
                id: string;
                created_at: string;
                updated_at: string;
                user: {
                  id: string;
                  name: string;
                };
                account: {
                  id: string;
                  platform_username: string;
                };
                work_platform: {
                  id: string;
                  name: string;
                  logo_url: string;
                };
                platform_username: string;
                full_name: string;
                first_name: string;
                last_name: string;
                nick_name: string;
                url: string;
                introduction: string;
                image_url: string;
                date_of_birth: string;
                external_id: string;
                platform_account_type: any;
                category: any;
                website: any;
                reputation: {
                  follower_count: number;
                  following_count: number;
                  subscriber_count: number;
                  content_count: number;
                  content_group_count: number;
                  watch_time_in_hours: number;
                };
                emails: string[];
                phone_numbers: string[];
                addresses: string[];
                gender: string;
                country: string;
                platform_profile_name: string;
                platform_profile_id: string;
                platform_profile_published_at: string;
                is_verified: boolean;
                is_business: boolean;
              }`}
            </Mui.Typography>
          </Mui.AccordionDetails>
        </Mui.Accordion>

        <Mui.Accordion>
          <Mui.AccordionSummary>Contents</Mui.AccordionSummary>
          <Mui.AccordionDetails>
            <Mui.Typography whiteSpace={"pre"}>
              {`{
                  id: string;
                  created_at: string;
                  updated_at: string;
                  user: {
                    id: string;
                    name: string;
                  };
                  account: {
                    id: string;
                    platform_username: string;
                  };
                  work_platform: {
                    id: string;
                    name: string;
                    logo_url: string;
                  };
                  engagement: {
                    like_count: number;
                    dislike_count: any;
                    comment_count: number;
                    impression_organic_count: number;
                    reach_organic_count: any;
                    save_count: any;
                    view_count: number;
                    watch_time_in_hours: any;
                    share_counts: number;
                    impression_paid_count: any;
                    reach_paid_count: any;
                  };
                  external_id: string;
                  title: string;
                  format: string;
                  type: string;
                  url: string;
                  media_url: any;
                  duration: any;
                  description: any;
                  visibility: string;
                  thumbnail_url: string;
                  published_at: string;
                  platform_profile_id: any;
                  platform_profile_name: any;
                  sponsored: any;
                  collaboration: any;
                  is_owned_by_platform_user: boolean;
                }
              `}
            </Mui.Typography>
          </Mui.AccordionDetails>
        </Mui.Accordion>
      </Mui.Stack>
    </Mui.Stack>
  );
};

export default ScoringFormulaSettings;
