import { useSnackbar } from "@/hooks/useSnakbar";
import { LocalStorageItems } from "@/utils/LocalStorageItems";
import { reactQueries } from "@/utils/ReactQueries";
import { getAuthentication } from "@/utils/getAuthentication";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { IPhylloConnectOptions, PhylloService } from "../phylloService";

export const usePhylloService = () => {
  const snackbar = useSnackbar();

  const [connectOptions, setConnectOptions] = useState<IPhylloConnectOptions>();

  const getPhylloSDK = useQuery({
    queryKey: [reactQueries.phylloService.getPhylloSDK],
    queryFn: PhylloService.getPhylloSDK,
    retry: false,
    enabled: false,
  });

  useEffect(() => {
    if (!connectOptions || !getPhylloSDK.data) {
      return;
    }

    const { user } = getAuthentication();
    user.phyllo_sdk_token = getPhylloSDK.data;
    localStorage.setItem(LocalStorageItems.user, JSON.stringify(user));

    PhylloService.connect(connectOptions);
  }, [getPhylloSDK.data, connectOptions]);

  const connect = (phylloConnectOptions: IPhylloConnectOptions) => {

    phylloConnectOptions.onTokenExpired = () => {
      snackbar.show("warning", "Token expired! Creating a new token.");
      setConnectOptions(phylloConnectOptions);
      getPhylloSDK.refetch();
    };

    return PhylloService.connect(phylloConnectOptions);
  };

  return { connect };
};
