import Root from "Root";
import { SignIn } from "pages/SignIn";
import { Navigate, Route, Routes } from "react-router-dom";
import ScoringFormulaSettings from "./pages/admin/formula/ScoringFormulaSettings";
import { BrandleteScore } from "./pages/brandleteScore/BrandleteScore";
import { Dashboard } from "./pages/dashboard/Dashboard";
import { LandingPage } from "./pages/landingPage/LandingPage";
import { LinkSocial } from "./pages/linkSocial/LinkSocial";
import AthleteInformation from "./pages/signUp/AthleteInformation";
import { getAuthentication } from "./utils/getAuthentication";
import { SetUpAccount } from "./pages/signUp/SetupAccount";
import Sports from "./pages/signUp/Sports";
import Socials from "./pages/signUp/Socials";
import { Academics } from "./pages/signUp/Academics";
import { PublicDashboard } from "./pages/dashboard/PublicDashboard";

type Props = { Component: any; baseRoute?: string };

const PrivateRoute = (props: Props) => {
  const { isLoggedIn } = getAuthentication();
  const baseRoute = props.baseRoute || "";

  if (!isLoggedIn) {
    return <Navigate to={`/${baseRoute}`} />;
  }

  return <props.Component />;
};

const PublicRoute = (props: Props) => {
  const { isLoggedIn } = getAuthentication();

  if (isLoggedIn) {
    return <Navigate to="/dashboard" />;
  }

  return <props.Component />;
};

export const Router = () => {
  return (
    <Routes>
      <Route path="/" Component={Root}>
        <Route path="" element={<PublicRoute Component={LandingPage} />} />

        <Route path="join-team">
          <Route
            path="setup"
            index={true}
            element={<PublicRoute Component={SetUpAccount} />}
          />

          <Route
            path="athlete-information"
            element={
              <PrivateRoute
                Component={AthleteInformation}
                baseRoute="join-team"
              />
            }
          />

          <Route
            path="sports"
            element={<PrivateRoute Component={Sports} baseRoute="join-team" />}
          />

          <Route
            path="academics"
            element={
              <PrivateRoute Component={Academics} baseRoute="join-team" />
            }
          />

          <Route
            path="socials"
            element={<PrivateRoute Component={Socials} baseRoute="join-team" />}
          />

          <Route path="" element={<Navigate to="/join-team/setup" replace />} />
        </Route>
        <Route path="sign-in" element={<PublicRoute Component={SignIn} />} />

        <Route
          path=":username"
          element={<PublicRoute Component={PublicDashboard} />}
        />

        {/* PRIVATE ROUTES */}
        <Route
          path="link-social"
          element={<PrivateRoute Component={LinkSocial} />}
        />
        <Route
          path="brandlete-score"
          element={<PrivateRoute Component={BrandleteScore} />}
        />
        <Route
          path="dashboard"
          element={<PrivateRoute Component={Dashboard} />}
        />

        <Route
          path="settings-scoring-formula-update"
          element={<PrivateRoute Component={ScoringFormulaSettings} />}
        />
      </Route>
    </Routes>
  );
};
