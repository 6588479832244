import * as Mui from "@mui/material";
import { IPublicUser } from "../data/PublicUsersData";
import { PropsWithChildren, useMemo } from "react";
import { paletteOptions } from "@/theme/BrandleteTheme";
import Cards from "@/compontents/cards/Cards";

interface RowProps extends PropsWithChildren, Mui.StackProps {
  hasBorder?: boolean;
}

const Row = (props: RowProps) => {
  const { hasBorder, ...stackProps } = props;

  const bottomBorders = useMemo(() => {
    return hasBorder
      ? {
          borderBottom: "1px solid",
          borderColor: "info.100",
        }
      : {};
  }, []);

  return (
    <Mui.Stack
      direction={"row"}
      justifyContent={"space-between"}
      sx={{ ...bottomBorders }}
      {...stackProps}
    >
      {props.children}
    </Mui.Stack>
  );
};

const publicDashboardPalette = {
  brandScore: "#00FFB3",
};

interface Props {
  userData?: IPublicUser;
}

export const CardPlayer = (props: Props) => {
  if (!props.userData) {
    return (
      <Mui.Card>
        <Mui.CardContent>Users data not found!</Mui.CardContent>
      </Mui.Card>
    );
  }

  return (
    <Cards type="dark-glow">
      <Mui.Box
        sx={{
          height: "400px",
          background: "linear-gradient(90deg, #D7DBDE 9.24%, #DFE2E7 91.36%)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom",
          backgroundImage: ` url(${props.userData.avatar}), linear-gradient(90deg, #D7DBDE 9.24%, #DFE2E7 91.36%)`,
          pt: {
            xs: "20px",
            md: "40px",
          },
          pl: {
            xs: "20px",
            md: "40px",
          },
        }}
      >
        <Mui.Typography fontSize={"40px !important"} fontStyle={"italic"}>
          {props.userData.jerseyNumber}
        </Mui.Typography>
      </Mui.Box>

      <Mui.CardContent>
        <Mui.Stack spacing={2} padding={2}>
          <Row alignItems={"center"} spacing={2}>
            <Mui.Typography variant="h3">{props.userData.name}</Mui.Typography>

            <Mui.Stack
              alignItems={"center"}
              justifyContent={"center"}
              position={"relative"}
              height={"75px"}
              width={"75px"}
              sx={{
                ":after": {
                  content: '" "',
                  position: "absolute",
                  width: "120%",
                  height: "100%",
                  borderTop: `12px solid ${paletteOptions.background.default}`,
                  borderBottom: `12px solid ${paletteOptions.background.default}`,
                },
                ":before": {
                  content: '""',
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  border: "3px solid yellow",
                  borderRadius: "50%",
                  borderColor: publicDashboardPalette.brandScore,
                  opacity: 0.25,
                },
              }}
              color={publicDashboardPalette.brandScore}
            >
              <Mui.Typography variant="caption" lineHeight={"25px"}>
                Brand
              </Mui.Typography>
              <Mui.Typography variant="h4" lineHeight={"20px"}>
                {props.userData.brandScore}
              </Mui.Typography>
              <Mui.Typography variant="caption" lineHeight={"15px"}>
                Score
              </Mui.Typography>
            </Mui.Stack>
          </Row>

          <Row hasBorder>
            <Mui.Typography variant="caption" color="info.100">
              TEAM RANK / LEAGUE RANK
            </Mui.Typography>

            <Mui.Typography variant="body2">
              {props.userData.teamRank} | {props.userData.leagueRank}
            </Mui.Typography>
          </Row>

          <Row hasBorder>
            <Mui.Typography variant="caption" color="info.100">
              SCHOOL
            </Mui.Typography>

            <Mui.Typography variant="body2">
              {props.userData.university}
            </Mui.Typography>
          </Row>

          <Row hasBorder>
            <Mui.Typography variant="caption" color="info.100">
              SPORT
            </Mui.Typography>

            <Mui.Typography variant="body2">
              {props.userData.sport}
            </Mui.Typography>
          </Row>

          <Row hasBorder>
            <Mui.Typography variant="caption" color="info.100">
              CLASS
            </Mui.Typography>

            <Mui.Typography variant="body2">
              {props.userData.class}
            </Mui.Typography>
          </Row>

          <Row hasBorder>
            <Mui.Typography variant="caption" color="info.100">
              BIRTHDATE
            </Mui.Typography>

            <Mui.Typography variant="body2">
              {props.userData.birthday}
            </Mui.Typography>
          </Row>

          <Row hasBorder>
            <Mui.Typography variant="caption" color="info.100">
              HT / WT
            </Mui.Typography>

            <Mui.Typography variant="body2">
              {props.userData.height} | {props.userData.weight} lbs
            </Mui.Typography>
          </Row>
        </Mui.Stack>
      </Mui.CardContent>
    </Cards>
  );
};
