export const reactQueries = {
  scoreCalculate: "score-calculate",
  dashboard: {
    getData: "dashboard-get-data",
  },
  phylloService: {
    getPhylloSDK: "get-phyllo-sdk-token",
  },
  settings: {
    scoring: "scoring-formulas",
  },
};

export const reactMutations = {
  sigIn: "sign-in",
  signOut: "sign-out",
  signUp: {
    setup: "sign-up",
    saveInformation: "save-information",
  },
  updateLink: "update-link",
  settings: {
    scoring: "update-scoring-formula",
  },
};
