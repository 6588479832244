import { reactQueries } from "@/utils/ReactQueries";
import * as Mui from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { AppSettings } from "compontents/app/AppSettings";
import { useNavigate } from "react-router-dom";
import { BrandleteScoreService } from "./BrandleteScoreService";

export const BrandleteScore = () => {
    const navigate = useNavigate();

    const onGoDashboard = () => {
        navigate("/dashboard");
    }
    
    const scoreQuery = useQuery({
        queryKey: [reactQueries.scoreCalculate],
        queryFn: BrandleteScoreService.calculateScore,
        refetchOnWindowFocus: false
    });

    return(
        <Mui.Container
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: `calc(100vh - ${AppSettings.appbar.height})`
            }}
        >
            <Mui.Grid 
                container 
                spacing={2}
            >
                <Mui.Grid item xs={12} display="flex" justifyContent="center">
                    <Mui.Typography variant="subtitle1" color="primary.600">
                        Your Brandlete Score is...
                    </Mui.Typography>
                </Mui.Grid>

                <Mui.Grid item xs={12} display="flex" justifyContent="center" alignItems="baseline">
                    <Mui.Typography variant="h1" color="text.primary">
                        {scoreQuery.data || 0}
                    </Mui.Typography>
                    <Mui.Typography variant="body1" color="primary.700" fontWeight="bold">
                        /1000
                    </Mui.Typography>
                </Mui.Grid>

                <Mui.Grid item xs={12} mx={"auto"} display="flex" justifyContent="center">
                        <Mui.Typography variant="body1" color="primary.500">
                            Looking Good!
                        </Mui.Typography>
                </Mui.Grid>

                <Mui.Grid item xs={12}  mx={"auto"} display="flex" justifyContent="center">
                        <Mui.Typography 
                            variant="body2" 
                            color="text.secondary"
                            style={{fontWeight:"normal"}}
                            textAlign="center"
                        >
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. 
                        </Mui.Typography>
                    
                </Mui.Grid>

                <Mui.Grid item xs={12} mx={"auto"} display="flex" justifyContent="center">
                    <Mui.Button variant="contained" onClick={onGoDashboard}>
                        Go to Dashboard
                    </Mui.Button>
                </Mui.Grid>
            </Mui.Grid>
        </Mui.Container>
    )
}