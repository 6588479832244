interface IOptions {
  defaultValue: string;
}

export const useLocalStorage = <ObjectType extends object | null>(
  key: string,
  options: IOptions = {
    defaultValue: "{}",
  }
) => {
  const getItem = () =>
    JSON.parse(localStorage.getItem(key) || options.defaultValue) as ObjectType;
  const setItem = (value: ObjectType) =>
    localStorage.setItem(key, JSON.stringify(value));

  return { getItem, setItem };
};
