import * as Mui from "@mui/material";
import styled from '@emotion/styled';
import { paletteOptions } from "@/theme/BrandleteTheme";

interface Props extends Mui.CardProps {
  type: "default" | "dark-glow",
}

const DarkGlowCard = styled(Mui.Card)({
  padding: 0,
  margin: 40,
  boxShadow: "0px 0px 40px 0px rgba(0,204,255,0.1)",
  background: paletteOptions.background.default,
  borderRadius: 6,
})

const Cards = (props: Props) => {
  const { type, ...cardprops } = props;

  if (props.type === "dark-glow") {
    return <DarkGlowCard {...cardprops} />;
  }
  
  return <Mui.Card {...cardprops} />;
}

export default Cards;