import { axiosInstance } from "@/axios/AxiosInstance";

export interface ISetting {
  id: number;
  name: string;
  type: string;
  value: string;
}

export const SettingsService = {
  getByName: (names: string[]) => {
    return axiosInstance.post<ISetting[]>("settings/getByName", {
      names,
    });
  },
  update: (setting: ISetting) => {
    return axiosInstance.post<ISetting>("settings/update", setting);
  },
};
