import * as MuiIcons from "@mui/icons-material";
import * as Mui from "@mui/material";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { ReactNode, useMemo, useState } from "react";
import { Control, Controller, RegisterOptions } from "react-hook-form";
import styled from "styled-components";
import { brandleteTheme } from "theme/BrandleteTheme";
import InputLabel from "./InputLabel";

const MuiTextField = styled(Mui.TextField)({
  ".MuiInputBase-root": {
    height: "40px",
    padding: "0px 8px",
  },
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
});

type InputTypes =
  | "button"
  | "checkbox"
  | "color"
  | "date"
  | "datetime-local"
  | "email"
  | "file"
  | "hidden"
  | "image"
  | "month"
  | "number"
  | "password"
  | "radio"
  | "range"
  | "reset"
  | "search"
  | "submit"
  | "tel"
  | "text"
  | "time"
  | "url"
  | "week";

type CommonProps = {
  name: string;
  control?: Control<any, any>;
  rules?: Partial<RegisterOptions>;
  label?: string;
};

interface InputProps
  extends CommonProps,
    Omit<Mui.TextFieldProps, "name" | "label"> {
  type: Exclude<InputTypes, "date">;
}

interface DateProps
  extends CommonProps,
    Omit<DatePickerProps<dayjs.Dayjs>, "name" | "label"> {
  type: "date";
}

const checkHasValue = (values: any[]) => {
  return !values.includes(
    (element: any) =>
      element !== undefined && element !== null && element !== ""
  );
};

const MyDatePicker = (props: {
  leftField?: any;
  componentProps?: any;
  value?: any;
  hasValue?: boolean;
}) => {
  return (
    <DatePicker
      {...props.leftField}
      {...props.componentProps}
      slots={{
        textField: MuiTextField,
      }}
      slotProps={{
        textField: {
          fullWidth: true,
          InputProps: {
            sx: {
              border: props.hasValue
                ? "1px solid white !important"
                : "inherit",
            },
          },
        },
      }}
      value={props.value}
    />
  );
};

const MyTextField = (props: {
  leftField?: any;
  componentProps?: Mui.TextFieldProps;
  value?: any;
  hasValue?: boolean;
  type?: any;
  error?: boolean;
  helperText?: ReactNode;
}) => {
  const [visiblePassword, setVisiblePassword] = useState(false);
  const onClickVisibility = () => {
    setVisiblePassword(!visiblePassword);
  };

  const inputType = useMemo<Mui.TextFieldProps["type"]>(() => {
    if (props.type === "password" && !visiblePassword) {
      return "password";
    } else if (props.type === "password" && visiblePassword) {
      return "text";
    }

    return props.type;
  }, [props.type, visiblePassword]);

  return (
    <MuiTextField
      {...props.leftField}
      {...props.componentProps}
      type={inputType}
      InputProps={{
        ...brandleteTheme.components?.MuiTextField?.defaultProps?.InputProps,
        sx: {
          border: props.hasValue ? "1px solid white !important" : "inherit",
        },
        endAdornment:
          props.type === "password" ? (
            visiblePassword ? (
              <Mui.IconButton onClick={onClickVisibility}>
                <MuiIcons.Visibility />
              </Mui.IconButton>
            ) : (
              <Mui.IconButton onClick={onClickVisibility}>
                <MuiIcons.VisibilityOff />
              </Mui.IconButton>
            )
          ) : null,
        ...props.componentProps?.InputProps,
      }}
      value={props.value}
      error={props.error}
      helperText={props.helperText}
    />
  );
};

type Props = InputProps | DateProps;

const Input = (props: Props) => {

  if (props.control) {
    return (
      <>
        <InputLabel label={props.label} />

        <Controller
          name={props.name}
          control={props.control}
          rules={props.rules}
          render={({ field, fieldState, formState }) => {
            // value not needed keeping inputs uncontrolled
            // control and value is being saved in react hook form
            const { value, ...leftField } = field;

            const hasValue = checkHasValue([value, props.value]);
            if (props.type === "date") {
              const { label, name, control, rules, type, ...componentProps } =
                props;

              return (
                <MyDatePicker
                  componentProps={componentProps}
                  value={value}
                  hasValue={hasValue}
                  leftField={leftField}
                />
              );
            }

            const { label, name, control, rules, type, ...componentProps } =
              props;

            return (
              <MyTextField
                componentProps={componentProps}
                leftField={leftField}
                value={value}
                hasValue={hasValue}
                type={props.type}
                error={formState.errors[name] ? true : false}
                helperText={formState.errors?.[name]?.message as string}
              />
            );
          }}
        />
      </>
    );
  } else if (props.type === "date") {
    const { label, name, control, rules, type, ...componentProps } = props;
    return (
      <>
        <InputLabel label={props.label} />
        <MyDatePicker
          componentProps={componentProps}
          value={props.value}
          hasValue={checkHasValue([props.value])}
        />
      </>
    );
  } else {
    const { label, name, control, rules, type, ...componentProps } = props;
    return (
      <>
        <InputLabel label={props.label} />
        <MyTextField
          componentProps={componentProps}
          value={props.value}
          hasValue={checkHasValue([props.value])}
          type={type}
        />
      </>
    );
  }
};

export default Input;
