import * as Mui from "@mui/material";
import { PropsWithChildren } from "react";

type Props = {};

const SignUpBody = (props: Props & PropsWithChildren) => {
  return <Mui.Box mt={4}>{props.children}</Mui.Box>;
};

export default SignUpBody;
