import axios, { AxiosError } from "axios";
import { IAxiosError } from "./types/axios.types";
import { LocalStorageItems } from "@/utils/LocalStorageItems";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 10000,
});

// So that authorization is refreshed with page refresh
axiosInstance.defaults.headers.common = {
  Authorization: `Bearer ${localStorage.getItem(LocalStorageItems.accessToken)}`,
}

axiosInstance.interceptors.response.use((response) => {
  return response.data;
}, (error: AxiosError<IAxiosError>) => {
  return Promise.reject(error.response?.data || {
    message: error.message,
    status: error.status,
  });
})


export {axiosInstance};
