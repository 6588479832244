export enum EnumPhylloWorkPlatforms {
  TIKTOK = "de55aeec-0dc8-4119-bf90-16b3d1f0c987",
  INSTAGRAM = "9bb8913b-ddd9-430b-a66a-d74d846e6c66",
  YOUTUBE="14d9ddf5-51c6-415e-bde6-f8ed36ad7054",
  TWITTER="7645460a-96e0-4192-a3ce-a1fc30641f72",
  TWITCH="e4de6c01-5b78-4fc0-a651-24f44134457b",
  FACEBOOK="ad2fec62-2987-40a0-89fb-23485972598c",
  SUBTASK="fbf76083-710b-439a-8b8c-956f607ef2c1",
  INSTAGRAM_LITE="fb7cafcf-60ed-468a-ad6d-6bb6899d0871",
  ADSENSE="3e6f4cdd-2119-4125-aa0d-86eee4e20fcc",
  SPOTIFY="1db0b86c-a547-4119-9834-da927171a685",
  LINKEDIN="36410629-f907-43ba-aa0d-434ca9c0501a",
  BEEHIIV="24fcaa09-0a79-4deb-b074-d8efd900265b",
}

export enum EnumPhylloWorkPlatformNames {
  TIKTOK = 'TIKTOK',
  INSTAGRAM = 'INSTAGRAM',
  YOUTUBE = 'YOUTUBE',
  TWITTER = 'TWITTER',
  TWITCH = 'TWITCH',
  FACEBOOK = 'FACEBOOK',
  SUBTASK = 'SUBTASK',
  INSTAGRAM_LITE = 'INSTAGRAM_LITE',
  ADSENSE = 'ADSENSE',
  SPOTIFY = 'SPOTIFY',
  LINKEDIN = 'LINKEDIN',
  BEEHIIV = 'BEEHIIV',
  X = 'X',
}
