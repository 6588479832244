import * as Mui from "@mui/material";
import { useWindowSize } from "@uidotdev/usehooks";
import basketball from "assets/basketball.png";
import { AppSettings } from "compontents/app/AppSettings";
import { useNavigate } from "react-router-dom";
import { brandleteTheme } from "theme/BrandleteTheme";

export const LandingPage = () => {
  const navigate = useNavigate();
  const windowSize = useWindowSize();

  return (
    <>
      <Mui.Box
        sx={{
          backgroundColor: brandleteTheme.palette.background.default,
          backgroundImage: `url(${basketball})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          position: "fixed",
          height: "100vh",
          width: "140vw",
          marginTop: `-${AppSettings.appbar.height}`,
          backgroundPositionX: {
            xs: "-20vw",
            md: "0",
          },
          backgroundBlendMode: "exclusion",
          zIndex: -1,
        }}
      />

      <Mui.Container maxWidth="xs">
        <Mui.Stack
          height={
            windowSize.height &&
            windowSize.width &&
            windowSize.height > windowSize.width
              ? `calc(100vh - ${AppSettings.appbar.height})`
              : "fit-content"
          }
          justifyContent={
            windowSize.height && windowSize.height < 500 ? "start" : "center"
          }
          p={4}
          spacing={4}
          marginLeft={"auto"}
          marginRight={"auto"}
        >
          <Mui.Typography variant="h1" color="paragraph.primary" fontSize={50}>
            CHANGING THE WAY WE FOLLOW SPORTS, FOREVER.
          </Mui.Typography>

          <Mui.Typography variant="body1" color="paragraph.passive" fontSize={24}>
            We show Athletes their Personal Score and we Guide Them to reach
            their Personal Goals
          </Mui.Typography>

          <Mui.Button
            variant="contained"
            size="large"
            color="primary"
            onClick={() => navigate("join-team")}
            sx={{ width: "100%" }}
          >
            JOIN THE TEAM
          </Mui.Button>
        </Mui.Stack>
      </Mui.Container>
    </>
  );
};
