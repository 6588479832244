import { CssBaseline, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Router } from "Router";
import { AppProvider } from "context/AppContext";
import { SnackbarProvider } from "notistack";
import { HashRouter } from "react-router-dom";
import { brandleteTheme } from "theme/BrandleteTheme";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

//  React Slick library css import 
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const queryClient = new QueryClient();

function App() {
  return (
    <ThemeProvider theme={brandleteTheme}>
      <CssBaseline />

      <QueryClientProvider client={queryClient}>
        <SnackbarProvider maxSnack={5}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <AppProvider>
              <HashRouter>
                <Router />
              </HashRouter>
            </AppProvider>
          </LocalizationProvider>
        </SnackbarProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
