import { createContext, PropsWithChildren } from "react";

// Context declaration
interface IAppContext {}

// Context initialization (default values)
export const AppContext = createContext<IAppContext>({});

// Context provider
export const AppProvider = (props: PropsWithChildren) => {
  return <AppContext.Provider value={{}}>{props.children}</AppContext.Provider>;
};
