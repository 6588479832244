import { axiosInstance } from "@/axios/AxiosInstance";
import { IAxiosError } from "@/axios/types/axios.types";
import { IAuthData } from "@/types-dto/IAuthData";
import { LocalStorageItems } from "@/utils/LocalStorageItems";

export interface ISignUpPayload {
  email: string;
  username: string;
  password: string;
  fullname: string;
}

export interface ISignInPayload {
  username: string;
  password: string;
  fullname: string;
}

const login = (userdata: IAuthData) => {
  axiosInstance.defaults.headers.common = {
    Authorization: `Bearer ${userdata.accessToken}`,
  };
  localStorage.setItem(LocalStorageItems.accessToken, userdata.accessToken);
  localStorage.setItem(LocalStorageItems.user, JSON.stringify(userdata.user));
}

export const AuthenticationService = {
  signup: (data: ISignUpPayload) => {
    return axiosInstance.post("auth/signup", data).then(
      (response) => {
        login(response);
        return response;
      },
      (error: IAxiosError) => {
        return Promise.reject(error);
      }
    );
  },
  signin: (data: ISignInPayload) => {
    return axiosInstance.post<IAuthData>('auth/login', data).then(
      (response) => {
        login(response);
        return response.user;
      },
      (error: IAxiosError) => {
        return Promise.reject(error);
      }
    )
  },
  signout: () => {
    localStorage.setItem(LocalStorageItems.accessToken, '');
    localStorage.setItem(LocalStorageItems.phylloId, '');
    localStorage.setItem(LocalStorageItems.phylloSdkToken, '');
    return axiosInstance.post('auth/logout');
  },
  checkToken: () => {
    return axiosInstance.post('auth/check').then(
      response => response,
      (error: IAxiosError) => Promise.reject(error)
    )
  }
};
