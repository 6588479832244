import * as Mui from "@mui/material";
import { useIsFetching, useIsMutating } from "@tanstack/react-query";
import { AppBar } from "compontents/app/AppBar";
import { AppSettings } from "compontents/app/AppSettings";
import { Outlet } from "react-router-dom";
import { getAuthentication } from "./utils/getAuthentication";

interface Props {}

const Root = (props: Props) => {
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();

  const isLoading = isMutating > 0 || isFetching > 0;

  const { isLoggedIn } = getAuthentication();

  return (
    <>
      <AppBar />

      <Mui.Box
        sx={{
          mt: `calc(${AppSettings.appbar.height} + ${
            isLoggedIn ? "40px" : "0px"
          })`,
        }}
      >
        <Outlet />
      </Mui.Box>

      <Mui.Backdrop open={isLoading} sx={{ zIndex: 1200 }}>
        <Mui.CircularProgress />
      </Mui.Backdrop>
    </>
  );
};

export default Root;
