import { IUser } from "@/types-dto/IUser";
import { LocalStorageItems } from "@/utils/LocalStorageItems";

export const getAuthentication = () => {
  const accessToken = localStorage.getItem(LocalStorageItems.accessToken);

  const storedUser = localStorage.getItem(LocalStorageItems.user);
  const user: IUser = storedUser ? JSON.parse(storedUser) : {};

  const isLoggedIn = accessToken !== null && accessToken !== undefined && accessToken !== '';

  return {
    isLoggedIn,
    user
  }
}